.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center_item{
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_lg{
  font-weight: 600;
  font-size: 30px;
}
.title_md{
  font-weight: 600;
  font-size: 16px;
}

.btn_lg{
  border-radius: 15px !important;
  width: 100%;
  height: 40px;
}
.btn_system{
  border-radius: 8px !important;
  width: 100%;
  height: 40px;
}

.btn_square{
  border-radius: 0px !important;
  width: 100%;
  height: 40px;
  padding: 1px !important;
}
.btn_square:hover{
  color: white;
}

.popover-close-session > .MuiPopover-paper{
  right: 0 !important;
  left: initial !important;
  margin-right: 20px !important;
  margin-top: 50px !important;
  padding: 15px 10px !important;
  min-width: 150px !important;
  width: 200px !important;
}

.input-date::-webkit-calendar-picker-indicator {
  display: none !important;
  color: #61dafb !important;
}

.input-date::-webkit-calendar-picker-indicator {
  display: none;
}

.react-colorful__hue-pointer {
  width: 15px !important;
  height: 15px !important;
  border-radius: 0;
}
.react-colorful__hue {
  height: 20px !important;
  border-radius: 0 0 4px 4px;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-focused {
    /* border-color: rgb(236, 31, 36) !important;
  box-shadow: 0 0 0 2px rgb(236 31 36 / 30%) !important; */
    border-color: none;
    box-shadow: none;
}

.flow-transition {
    transition: all 0.15s ease-in-out;
}

.ant-btn[disabled] {
    background-color: #fff !important;
}

.ant-btn[disabled]:hover {
    background-color: #fff !important;
}

.ant-btn:not(.ant-btn-circle){
    border-radius:4px;
}

.ant-btn-primary {
    background-color: #003ca7;
    border: 1px #003ca7 solid;
    transition: all 0.1s ease-in-out;
    color: white;
}

.ant-btn-primary:not(.ant-btn-circle){
    border-radius:4px;
}

.ant-btn-primary span {
    color: white;
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
    background-color: #0048c4;
    border: 1px #0048c4 solid;
    /* box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.3); */
    /* transform: scale(1.01); */
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    background-color: #005af5 !important;
    border: 1px #005af5 solid;
}

.ant-input,
.ant-picker,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-form-item-control-input-content .ant-input-number input {
    border: none;
    border-bottom: 2px solid rgba(230, 230, 230, 1);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-focused,
.ant-input-number:focus,
.ant-input-number-focused {
    /* border-bottom: 1px solid rgba(230,230,230, 1) */
    box-shadow: none;
}

.ant-form-item-control-input-content,
.ant-form-item-control-input-content .ant-input-number {
    width: 100% !important;
    border: none;
}

.ant-form-item-control-input-content .ant-input-number input:focus,
.ant-form-item-control-input-content .ant-input-number input:active,
.ant-form-item-control-input-content .ant-input-number input:hover {
    border-bottom: 2px solid #40a9ff !important;
}

.custom-btn {
    background-color: #003ca7;
    color: #ffffff;
    /* border-radius: 10px; */
    transition: all 0.2s ease-in-out;
    /* padding: 20px auto; */
    /* height: 35px; */
}

.circle {
    border-radius: 50%;
}

.elevation-1 {
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

table .ant-table-column-sorter-up.active,
table .ant-table-column-sorter-down.active {
    /* color: rgba(236, 31, 36, 1) !important; */
}

.ant-table-filter-trigger.active,
.ant-table-filter-trigger.active span {
    /* color: rgba(236, 31, 36, 1) !important; */
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    /* border-color: rgba(236, 31, 36, 1) !important; */
}

.ant-pagination-item-active a {
    /* color: rgba(236, 31, 36, 1) !important; */
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
    /* border-color: rgba(236, 31, 36, 0.6) !important; */
    transition: all 0.3s;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
    /* color: rgba(236, 31, 36, 0.6) !important; */
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    /* color: rgba(236, 31, 36, 1);
    border-color: rgba(236, 31, 36, 1); */
}

.ant-modal-root .ant-modal-mask {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
}

.ant-drawer-mask {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
}

.ant-modal .ant-modal-close-icon {
    color: white;
}

.ant-modal-header {
    background-color: #003ca7;
    color: white;
}

.ant-modal-header .ant-modal-title {
    color: white;
}

.ant-modal-header .ant-modal-title {
    color: white;
}

.ant-input {
    /* background-color: rgb(242, 242, 242);
    border: none;
    border-radius: 0;
    padding: 7px 10px; */
}

.ant-input:hover,
.ant-input:focus {
    /* border-color: #003CA7 !important;
    border-width: 1px !important; */
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-bottom: 2px solid #ff4d4f;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    background-color: rgb(242, 242, 242);
    border: 1px solid;
    /* border-color: #ff4d4f; */
}

.ant-spin {
    color: #003ca7;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #003ca7;
}

.ant-tag-checkable {
    border: 1px solid #d9d9d9;
}

.ant-tag-checkable-checked {
    background-color: #003ca7;
    border: none;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #003ca7;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #000;
}

#loading-wrapper {
    animation-name: fade;
    animation-duration: 500ms;
    animation-iteration-count: unset;
    animation-timing-function: linear;
}

.loading-icon {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #003ca7;
}

.loading-icon-reverse {
    animation-name: spin-reverse;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #003ca7;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    width: 1px;
    background: #888;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    overflow-y: "auto";
}

.ant-input-affix-wrapper {
    border: none;
    border-bottom: 2px solid rgba(230, 230, 230, 1);
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-bottom: 2px solid #ff4d4f;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    outline: none;
    outline-width: 0;
    background-color: #fff;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover {
    border: none;
    /* border-bottom: 2px solid #ff4d4f; */
}

/* .ant-form-item-has-error :not(.ant-input-disabled).ant-input, */
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
/* .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover, */
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    border: none;
    border-bottom: 2px solid #ff4d4f;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding: 0;
}

.ant-form-vertical .ant-form-item {
    margin-bottom: 24px;
}

.ant-input-affix-wrapper {
    padding: 0 11px;
}

.card_alm{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 80px;
    cursor: pointer;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
}

.card_alm:hover{
    scale: 0.9;
    transition: 350ms;
}

.text_card_alm{
    color: red;
    font-weight: 600;
    font-size: 15px;
}

.card_alm:hover .text_card_alm{
    transition: 350ms;
    scale: 1.3;
    z-index: 90;
}

.contain_img_gen{
    width: 98%;
    max-height: 750px !important;
    overflow-y: scroll;
    padding: 0px 2px;
    display: flex;
    flex-wrap: wrap;
}
.contain_img_gen::-webkit-scrollbar{
    width: 5px !important;
}

.img_item_gen{
    padding: 0px 2px;
    margin-bottom: 5px;
}

@media (max-width: 600px) {
    .img_item_gen{
        width: 100%;
    }
    .modal_carga{
        width: 500px;
    }
}

@media (min-width: 600px) and (max-width: 899px) {
    .img_item_gen{
        width: 50%;
    }
}

@media (min-width: 900px) {
    .img_item_gen{
        width: 100%;
    }
    .modal_carga{
        width: 800px;
    }
}

.cont_alt{
    z-index: 6000;
}

.card {
    padding: 2em;
}

.read-the-docs {
color: #888;
}

.horizontal-container {
display: flex;
justify-content: center;
}

.sidebar {
display: flex;
flex-direction: column;
height: 100%;
width: 30vw;
justify-content: center;
align-items: center;
background-color: transparent;
padding: 0px 20px;
}


.sidebar-node-information {
display: flex;
flex-direction: column;
align-items: start;
padding: 1em 2em;
background-color: #575E3B;
color: white;
border-radius: 12px;
width: 100%;
max-height: 600px;
overflow-y: scroll;
}

.sidebar-node-information > div {
display: flex;
flex-direction: column;
align-items: start;
}

.collapser {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    overflow: hidden;
}

.collapser-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    color: #575E3B;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    font-family: Roboto, 'san-serif';
}

.collapser-label .icon {
    display: flex;
    align-items: center;
}

.collapser-content {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    padding: 0;
    background-color: #f5f5f5;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.collapser-content.open {
    max-height: 100%; /* Ajusta según tu contenido */
    opacity: 1;
    padding: 10px;
}

/* Rotar la flecha cuando está abierto */
.icon svg {
    transition: transform 0.3s ease;
}

.icon svg.open {
    transform: rotate(180deg);
}


.swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
}

.swiper-button-prev {
left: 10px;
}

.swiper-button-next {
right: 10px;
}

.input-date_salida::-webkit-datetime-edit-text,
.input-date_salida::-webkit-inner-spin-button,
.input-date_salida::-webkit-clear-button{
    display: none;
}

.css-118d58w-MuiButtonBase-root-MuiTableSortLabel-root .MuiTableSortLabel-icon{
    color: white !important;
}